import styled, { css } from 'styled-components';

export const Link = styled.a`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  height: 44px;
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
  position: relative;
  justify-content: center;
  vertical-align: middle;

  &:after {
    background-color: var(--headerBackgroundColor);
    content: '';
    height: 5px;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.overlay.dark};
  }

  ${({ active }) =>
    active &&
    css`
      pointer-events: none;

      &:after {
        opacity: 1;
      }
    `}
`;
