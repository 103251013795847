import React from 'react';
import NavLinks from '../nav-links';
import Icon from '../icon';
import SocialLinks from '../social-links';
import Text from '../text';
import * as Styles from './styles';

function Header({ menuOpen, onMenuClick }) {
  return (
    <Styles.Wrapper>
      <Styles.Logo>
        <Text variant="h4" shade="dark">
          Steve Meredith.
        </Text>
      </Styles.Logo>
      <Styles.Nav>
        <NavLinks />
      </Styles.Nav>
      <Styles.Social>
        <SocialLinks />
      </Styles.Social>
      <Styles.MenuButton onClick={onMenuClick} aria-label="Menu">
        {!menuOpen && <Icon icon="menu" large />}
        {menuOpen && <Icon icon="close" large />}
      </Styles.MenuButton>
    </Styles.Wrapper>
  );
}

export default Header;
