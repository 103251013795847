import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { TransitionContext } from '../../context';

function TransitionLink({ noTransition, fall, onClick, children, to, ...rest }) {
  const setTransition = useContext(TransitionContext);

  const selectTransition = () => {
    if (fall) return 'fall';
    if (noTransition) return '';
    return 'scaleDownFromRight';
  };

  return (
    <Link
      to={to}
      onClick={() => {
        setTransition(selectTransition());
        if (onClick) onClick();
      }}
      {...rest}>
      {children}
    </Link>
  );
}

export default TransitionLink;
