import React from 'react';

import Icon from '../icon';

import * as Styles from './styles';

function SocialLink({ icon, name, ...rest }) {
  return (
    <Styles.Link target="_blank" rel="noopener noreferrer" {...rest}>
      <Icon icon={icon} medium />
    </Styles.Link>
  );
}

function SocialLinks() {
  return (
    <>
      <SocialLink
        href="https://github.com/steveeeie"
        icon="github"
        aria-label="View Github Profile"
      />
      <SocialLink
        href="https://codepen.io/steveeeie/"
        icon="codepen"
        aria-label="View CodePen Profile"
      />
      <SocialLink
        href="https://twitter.com/steveeeie"
        icon="twitter"
        aria-label="View Twitter Profile"
      />
      <SocialLink
        href="https://uk.linkedin.com/in/steve-meredith-b96b0227"
        icon="linkedin"
        aria-label="View LinkedIn Profile"
      />
    </>
  );
}

export default SocialLinks;
