import React from 'react';

import * as Styles from './styles';
import paths from './paths';

function Icon({ icon, ...rest }) {
  return (
    <Styles.Svg viewBox="0 0 24 24" {...rest}>
      <path d={paths[icon]} />
    </Styles.Svg>
  );
}

export default Icon;
