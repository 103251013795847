import React, { useContext } from 'react';

import { LocationContext } from '../../context';
import TransitionLink from '../transition-link';
import * as Styles from './styles';

function MobileNavLink({ children, to, icon, name, ...rest }) {
  const location = useContext(LocationContext);
  let active;

  if (to === '/') {
    active = to === location.pathname;
  } else {
    active = location.pathname.includes(to);
  }

  return (
    <Styles.Link as={TransitionLink} to={to} active={active} noTransition {...rest}>
      {children}
    </Styles.Link>
  );
}

function MobileNavLinks({ onClick = () => {} }) {
  return (
    <>
      <MobileNavLink to="/" onClick={onClick}>
        Home
      </MobileNavLink>
      <MobileNavLink to="/technologies/" onClick={onClick}>
        Technologies
      </MobileNavLink>
      <MobileNavLink to="/experience/" onClick={onClick}>
        Experience
      </MobileNavLink>
      <MobileNavLink to="/projects/" onClick={onClick}>
        Projects
      </MobileNavLink>
    </>
  );
}

export default MobileNavLinks;
