import styled from 'styled-components';

export const Link = styled.a`
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  vertical-align: middle;
  width: 40px;

  &:hover {
    background-color: var(--headerLinkColor);
  }
`;
