// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-experience-index-jsx": () => import("./../../../src/pages/experience/index.jsx" /* webpackChunkName: "component---src-pages-experience-index-jsx" */),
  "component---src-pages-experience-section-ff-jsx": () => import("./../../../src/pages/experience/section-ff.jsx" /* webpackChunkName: "component---src-pages-experience-section-ff-jsx" */),
  "component---src-pages-experience-section-freelance-jsx": () => import("./../../../src/pages/experience/section-freelance.jsx" /* webpackChunkName: "component---src-pages-experience-section-freelance-jsx" */),
  "component---src-pages-experience-section-pace-jsx": () => import("./../../../src/pages/experience/section-pace.jsx" /* webpackChunkName: "component---src-pages-experience-section-pace-jsx" */),
  "component---src-pages-experience-section-sky-jsx": () => import("./../../../src/pages/experience/section-sky.jsx" /* webpackChunkName: "component---src-pages-experience-section-sky-jsx" */),
  "component---src-pages-experience-section-vc-1-jsx": () => import("./../../../src/pages/experience/section-vc-1.jsx" /* webpackChunkName: "component---src-pages-experience-section-vc-1-jsx" */),
  "component---src-pages-experience-section-vc-2-jsx": () => import("./../../../src/pages/experience/section-vc-2.jsx" /* webpackChunkName: "component---src-pages-experience-section-vc-2-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-section-andy-kaufman-jsx": () => import("./../../../src/pages/projects/section-andy-kaufman.jsx" /* webpackChunkName: "component---src-pages-projects-section-andy-kaufman-jsx" */),
  "component---src-pages-projects-section-bouncy-castle-jsx": () => import("./../../../src/pages/projects/section-bouncy-castle.jsx" /* webpackChunkName: "component---src-pages-projects-section-bouncy-castle-jsx" */),
  "component---src-pages-projects-section-hockey-jsx": () => import("./../../../src/pages/projects/section-hockey.jsx" /* webpackChunkName: "component---src-pages-projects-section-hockey-jsx" */),
  "component---src-pages-projects-section-page-transition-jsx": () => import("./../../../src/pages/projects/section-page-transition.jsx" /* webpackChunkName: "component---src-pages-projects-section-page-transition-jsx" */),
  "component---src-pages-projects-section-timeline-jsx": () => import("./../../../src/pages/projects/section-timeline.jsx" /* webpackChunkName: "component---src-pages-projects-section-timeline-jsx" */),
  "component---src-pages-technologies-index-jsx": () => import("./../../../src/pages/technologies/index.jsx" /* webpackChunkName: "component---src-pages-technologies-index-jsx" */),
  "component---src-pages-technologies-section-html-jsx": () => import("./../../../src/pages/technologies/section-html.jsx" /* webpackChunkName: "component---src-pages-technologies-section-html-jsx" */),
  "component---src-pages-technologies-section-jam-jsx": () => import("./../../../src/pages/technologies/section-jam.jsx" /* webpackChunkName: "component---src-pages-technologies-section-jam-jsx" */),
  "component---src-pages-technologies-section-js-jsx": () => import("./../../../src/pages/technologies/section-js.jsx" /* webpackChunkName: "component---src-pages-technologies-section-js-jsx" */),
  "component---src-pages-technologies-section-react-jsx": () => import("./../../../src/pages/technologies/section-react.jsx" /* webpackChunkName: "component---src-pages-technologies-section-react-jsx" */),
  "component---src-pages-technologies-section-webgl-jsx": () => import("./../../../src/pages/technologies/section-webgl.jsx" /* webpackChunkName: "component---src-pages-technologies-section-webgl-jsx" */)
}

