import styled from 'styled-components';

export const Menu = styled.aside`
  align-items: stretch;
  color: ${({ theme }) => theme.palette.text.light};
  justify-content: space-between;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  flex-direction: column;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: ${({ theme }) => theme.spacing(4)}px;
  height: 380px;
  width: calc(80% - ${({ theme }) => theme.spacing(4)}px);
  z-index: 1;
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`;
