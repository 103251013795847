import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import { LocationContext, TransitionContext } from '../context';
import Header from '../components/header';
import MobileMenu from '../components/mobile-menu';
import PageTransition from '../components/page-transition';

import GlobalStyles from './global-styles';
import * as Styles from './styles';
import theme from './theme';

function Layout({ children, location }) {
  const [transition, setTransition] = useState('scaleDownFromRight');
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <LocationContext.Provider value={location}>
        <TransitionContext.Provider value={setTransition}>
          <Helmet
            title="Steve Meredith - Frontend Developer"
            meta={[
              {
                name: 'description',
                content: `I'm Steve Meredith a Frontend Developer with over 10 years experience developing websites, applications and games with HTML, CSS, JS and React.`
              }
            ]}>
            <html lang="en" />
          </Helmet>

          <GlobalStyles pathname={location.pathname} />

          {menuOpen && <MobileMenu onClose={() => setMenuOpen(false)} />}

          <Styles.Wrapper menuOpen={menuOpen}>
            <Styles.Header>
              <Header
                menuOpen={menuOpen}
                onMenuClick={() => (!menuOpen ? setMenuOpen(true) : null)}
              />
            </Styles.Header>
            <Styles.Body>
              <PageTransition transitionKey={location.pathname} preset={transition}>
                {children}
              </PageTransition>
            </Styles.Body>
          </Styles.Wrapper>
        </TransitionContext.Provider>
      </LocationContext.Provider>
    </ThemeProvider>
  );
}

export default Layout;
