import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.grey};
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 600ms ease;
  z-index: 2;

  ${({ menuOpen }) =>
    menuOpen &&
    css`
      @media (max-width: ${({ theme }) => theme.breaks.xl}) {
        transform: translate3d(-80%, 0, 0);
      }
    `}
`;

export const Header = styled.div`
  flex: none;
`;

export const Body = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;

  > div {
    height: auto;
  }
`;
