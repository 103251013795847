import styled, { css } from 'styled-components';
import { spacing } from '@material-ui/system';

export const Text = styled.p`
  ${({ theme, align, variant, shade }) => {
    const type = theme.typography[variant];
    const color = theme.palette.text[shade || 'light'];

    return css`
      ${spacing};
      color: ${color};
      font-size: ${type.fontSize};
      font-weight: ${type.fontWeight};
      letter-spacing: ${type.letterSpacing};
      line-height: ${type.lineHeight};
      text-align: ${align || 'left'};

      ${type.media &&
        type.media.map(size => {
          return css`
            @media (max-width: ${theme.breaks[size.name]}) {
              font-size: ${size.fontSize};
              line-height: ${size.lineHeight};
            }
          `;
        })}
    `;
  }}
`;
