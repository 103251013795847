import styled, { css } from 'styled-components';
import { spacing } from '@material-ui/system';

export const Svg = styled.svg`
    ${spacing};
    fill: currentColor;
    height: 20px;
    width: 20px;
    ${props => props.small && small}
    ${props => props.medium && medium}
    ${props => props.large && large}
`;

const small = css`
  height: 12px;
  width: 12px;
`;

const medium = css`
  height: 24px;
  width: 24px;
`;

const large = css`
  height: 28px;
  width: 28px;
`;
