import styled from 'styled-components';

export const Wrapper = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.white};
  display: grid;
  grid-template-columns: 240px 1fr 240px;
  padding-left: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: ${({ theme }) => theme.breaks.xl}) {
    grid-template-columns: 1fr 60px;
  }
`;

export const Logo = styled.div``;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breaks.xl}) {
    display: none;
  }
`;

export const Social = styled.nav`
  align-items: center;
  background-color: var(--headerBackgroundColor);
  color: var(--headerColor);
  display: flex;
  height: 72px;
  padding: 0 ${({ theme }) => theme.spacing(4)}px;
  justify-content: space-between;
  width: 240px;
  transition: background-color 200ms linear, color 200ms linear;

  @media (max-width: ${({ theme }) => theme.breaks.xl}) {
    display: none;
  }
`;

export const MenuButton = styled.button`
  background-color: var(--headerBackgroundColor);
  display: none;
  color: var(--headerColor);
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(3)}px;

  @media (max-width: ${({ theme }) => theme.breaks.xl}) {
    display: inline-flex;
  }
`;
