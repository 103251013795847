import { keyframes } from 'styled-components';

export default {
  moveFromRight: {
    keyframes: keyframes`
      from { transform: translateX(100%); z-index: 3;}
      to { transform: translateX(0); z-index: 3;}
    `,
    duration: 600,
    timing: 'ease',
    fill: 'both'
  },
  scaleDown: {
    keyframes: keyframes`
      from { opacity: 1; transform: scale(1); }
      to { opacity: 0; transform: scale(0.8); }
    `,
    duration: 700,
    timing: 'ease',
    fill: 'both'
  },
  scaleUp: {
    keyframes: keyframes`
	    from { opacity: 0;  transform: scale(0.8); }
      to { opacity: 1; transform: scale(1); }
    `,
    duration: 700,
    timing: 'ease',
    fill: 'both'
  },
  rotateFall: {
    keyframes: keyframes`
      0% { transform: rotateZ(0deg); z-index: 3;}
      20% { transform: rotateZ(10deg); animation-timing-function: ease-out; z-index: 3;}
      40% { transform: rotateZ(17deg); z-index: 3;}
      60% { transform: rotateZ(16deg); z-index: 3;}
      100% { transform: translateY(100%) rotateZ(17deg); z-index: 3;}
    `,
    duration: 1000,
    timing: 'ease-in',
    fill: 'both',
    origin: '0% 0%'
  }
};
