import React, { useEffect } from 'react';

import MobileNavLinks from '../mobile-nav-links';
import SocialLinks from '../social-links';
import * as Styles from './styles';

function MobileMenu({ onClose }) {
  let wrapperRef = React.createRef();

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('touchend', handleClickOutside);
    window.addEventListener('mouseup', handleClickOutside);
    return () => {
      window.removeEventListener('touchend', handleClickOutside);
      window.removeEventListener('mouseup', handleClickOutside);
    };
  });

  return (
    <Styles.Menu ref={wrapperRef}>
      <MobileNavLinks onClick={onClose} />
      <Styles.Social>
        <SocialLinks />
      </Styles.Social>
    </Styles.Menu>
  );
}

export default MobileMenu;
