import { createGlobalStyle, css } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;800&display=swap');

  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
  }

  ${({ theme, pathname }) => {
    const selectColor = () => {
      if (pathname.includes("technologies")) {
        return {
          backgroundColor: theme.palette.background.yellow,
          linkColor: theme.palette.overlay.dark,
          color: theme.palette.text.dark,
        };
      }
      if (pathname.includes("experience")) {
        return {
          backgroundColor: theme.palette.background.sky,
          linkColor: theme.palette.overlay.dark,
          color: theme.palette.text.dark,
        };
      }
      if (pathname.includes("projects")) {
        return {
          backgroundColor: theme.palette.background.green,
          linkColor: theme.palette.overlay.dark,
          color: theme.palette.text.dark,
        };
      }

      return {
        backgroundColor: theme.palette.background.red,
        linkColor: theme.palette.overlay.light,
        color: theme.palette.text.light,
      };
    };

    if (pathname) {
      return css`
        :root {
          --headerBackgroundColor: ${({ pathname }) =>
            selectColor().backgroundColor};
          --headerLinkColor: ${({ pathname }) => selectColor().linkColor};
          --headerColor: ${({ pathname }) => selectColor().color};
        }
      `;
    }
  }};

  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  html {
    font-size: 16px;
  }

  body {
    background-color: ${({ theme }) => theme.palette.background.grey};
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    text-rendering: optimizeLegibility
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .gatsby-image-wrapper{
    height: 100%;
    width: 100%;
  }
`;

export default GlobalStyles;
