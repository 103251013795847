import React, { useContext } from 'react';

import { LocationContext } from '../../context';
import TransitionLink from '../transition-link';
import * as Styles from './styles';

function NavLink({ children, to, icon, name, ...rest }) {
  const location = useContext(LocationContext);
  let active;

  if (to === '/') {
    active = to === location.pathname;
  } else {
    active = location.pathname.includes(to);
  }

  return (
    <Styles.Link as={TransitionLink} to={to} active={active} {...rest}>
      {children}
    </Styles.Link>
  );
}

function NavLinks() {
  return (
    <>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/technologies/">Technologies</NavLink>
      <NavLink to="/experience/">Experience</NavLink>
      <NavLink to="/projects/">Projects</NavLink>
    </>
  );
}

export default NavLinks;
