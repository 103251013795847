export default {
  breaks: {
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1140px",
  },
  palette: {
    background: {
      red: "#BE4242",
      lightRed: "#D14C4C",
      yellow: "#ffd24e",
      sky: "#00B8F7",
      blue: "#185BBA",
      purple: "#836FFF",
      orange: "#F1853E",
      green: "#47c764",
      grey: "#222222",
      greyLight: "#1b1b1b",
      white: "#fff",
    },
    overlay: {
      dark: "rgba(0, 0, 0, 0.075)",
      light: "rgba(255, 255, 255, 0.1)",
    },
    text: {
      light: "#fff",
      mid: "#a2a2a2",
      dark: "#292F35",
    },
  },
  spacing: (step) => [4, 8, 12, 16, 24, 32, 48, 64, 72][step],
  typography: {
    h1: {
      element: "h1",
      fontSize: "72px",
      fontWeight: 800,
      letterSpacing: 0,
      lineHeight: "72px",
      media: [
        {
          name: "sm",
          fontSize: "32px",
          lineHeight: "44px",
        },
      ],
    },
    h2: {
      element: "h2",
      fontSize: "48px",
      fontWeight: 800,
      letterSpacing: 0,
      lineHeight: "62px",
      media: [
        {
          name: "xl",
          fontSize: "32px",
          lineHeight: "44px",
        },
      ],
    },
    h3: {
      element: "h3",
      fontSize: "26px",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "58px",
      media: [
        {
          name: "lg",
          fontSize: "22px",
          lineHeight: "44px",
        },
      ],
    },
    h4: {
      element: "h2",
      fontSize: "22px",
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: "28px",
    },
    lead: {
      element: "p",
      fontSize: "24px",
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: "36px",
      media: [
        {
          name: "sm",
          fontSize: "20px",
          lineHeight: "32px",
        },
      ],
    },
    p: {
      element: "p",
      fontSize: "18px",
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: "28px",
      media: [
        {
          name: "md",
          fontSize: "16px",
          lineHeight: "28px",
        },
      ],
    },
  },
};
