module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Steve Meredith","short_name":"Steve Meredith","start_url":"/","background_color":"#292F35","theme_color":"#BE4242","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"358173899c21819c0ff0957e77a3cfad"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
