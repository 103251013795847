export default {
  scaleDownFromRight: {
    exit: {
      name: 'scaleDown'
    },
    enter: {
      name: 'moveFromRight'
    }
  },
  fall: {
    exit: {
      name: 'rotateFall'
    },
    enter: {
      name: 'scaleUp'
    }
  }
};
