import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import * as Styles from './styles';

const Text = ({ as, variant, children, ...rest }) => {
  const theme = useContext(ThemeContext);
  const component = as || theme.typography[variant].element;

  return (
    <Styles.Text as={component} variant={variant} {...rest}>
      {children}
    </Styles.Text>
  );
};

export default Text;
